import React from 'react';
import { any } from 'prop-types';
import styles from './ProgramBenefits.module.scss';
import { RichText } from 'prismic-reactjs';
import classnames from 'classnames';

const ProgramBenefits = (datas) => {
  // console.log('datas', datas);

  const { primary, items } = datas;

  const programBenefits = items.map((item) => item?.benefit_title);
  const referralCheck = items.map((item) => item?.referral_benefit_check);
  // const salesCheck = items.map((item) => item?.sales_benefit_check);
  const solutionCheck = items.map((item) => item?.solution_benefit_check);

  // console.log('referralCheck', referralCheck);

  return (
    <div className={styles.program_benefits_wrapper}>
      <div className={styles.container}>
        <div className={styles.title_wrapper}>
          <div className={styles.title}>
            <RichText render={primary?.title.richText} />
          </div>

          <div className={styles.subtitle}>
            <RichText render={primary?.description.richText} />
          </div>
        </div>

        <div className={styles.program_benefits_category_wrap}>
          <div className={styles.program_benefits_category_card}>
            <div className={styles.program_benefits_category_title}>
              <h6></h6>
            </div>
          </div>

          <div className={styles.program_benefits_category_card}>
            <div className={styles.program_benefits_category_title}>
              <RichText render={primary?.category_title_1.richText} />
            </div>
          </div>

          {/* <div className={styles.program_benefits_category_card}>
            <div className={styles.program_benefits_category_title}>
              <RichText render={primary?.category_title_2.richText} />
            </div>
          </div> */}

          <div className={styles.program_benefits_category_card}>
            <div className={styles.program_benefits_category_title}>
              <RichText render={primary?.category_title_3.richText} />
            </div>
          </div>
        </div>

        <div className={styles.program_benefits_list_wrapper}>
          <div className={styles.program_benefits_list_card}>
            {programBenefits &&
              programBenefits.map((item, index) => (
                <div className={styles.program_benefit} key={index}>
                  <RichText render={item.richText} />
                </div>
              ))}
          </div>

          <div className={styles.program_benefits_list_card}>
            {referralCheck &&
              referralCheck.map((item, index) => (
                <div
                  key={index}
                  className={classnames(styles.program_benefit_ischeck_wrap, {
                    [styles.even]: (index + 1) % 2 == 0,
                    [styles.odd]: (index + 1) % 2 !== 0,
                  })}
                >
                  {item ? (
                    <div className={styles.icon_wraper}>
                      <img src={primary?.icon?.url} alt="img" loading="lazy" />
                    </div>
                  ) : (
                    <>-</>
                  )}
                </div>
              ))}
          </div>

          {/* <div className={styles.program_benefits_list_card}>
            {salesCheck &&
              salesCheck.map((item, index) => (
                <div
                  key={index}
                  className={classnames(styles.program_benefit_ischeck_wrap, {
                    [styles.even]: (index + 1) % 2 == 0,
                    [styles.odd]: (index + 1) % 2 !== 0,
                  })}
                >
                  {item ? (
                    <div className={styles.icon_wraper}>
                      <img src={primary?.icon?.url} alt="img" loading="lazy" />
                    </div>
                  ) : (
                    <>-</>
                  )}
                </div>
              ))}
          </div> */}

          <div className={styles.program_benefits_list_card}>
            {solutionCheck &&
              solutionCheck.map((item, index) => (
                <div
                  key={index}
                  className={classnames(styles.program_benefit_ischeck_wrap, {
                    [styles.even]: (index + 1) % 2 == 0,
                    [styles.odd]: (index + 1) % 2 !== 0,
                  })}
                >
                  {item ? (
                    <div className={styles.icon_wraper}>
                      <img src={primary?.icon?.url} alt="img" loading="lazy" />
                    </div>
                  ) : (
                    <>-</>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

ProgramBenefits.propTypes = {
  datas: any,
};

export default ProgramBenefits;
