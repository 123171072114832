import React from 'react';

const RightArrow = () => {
  return (
    <>
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.75934 0.503907C7.63633 0.62582 7.53869 0.770862 7.47206 0.930671C7.40542 1.09048 7.37112 1.26189 7.37112 1.43501C7.37112 1.60813 7.40542 1.77954 7.47206 1.93935C7.53869 2.09916 7.63633 2.2442 7.75934 2.36612L12.0905 6.68068L2.12887 6.68068C1.78078 6.68068 1.44695 6.81885 1.20082 7.06479C0.954683 7.31072 0.816406 7.64429 0.816406 7.9921C0.816406 8.33991 0.954683 8.67347 1.20082 8.91941C1.44695 9.16535 1.78078 9.30351 2.12887 9.30351L12.0905 9.30351L7.75934 13.6181C7.5122 13.865 7.37336 14.2 7.37336 14.5492C7.37336 14.8984 7.5122 15.2333 7.75934 15.4803C8.00648 15.7272 8.34168 15.866 8.69119 15.866C9.0407 15.866 9.3759 15.7272 9.62304 15.4803L16.1854 8.9232C16.3049 8.79848 16.3985 8.65141 16.461 8.49044C16.5304 8.33346 16.5663 8.16372 16.5663 7.9921C16.5663 7.82047 16.5304 7.65074 16.461 7.49376C16.3985 7.33278 16.3049 7.18571 16.1854 7.06099L9.62304 0.503907C9.50103 0.38099 9.35587 0.283425 9.19594 0.216846C9.036 0.150267 8.86445 0.11599 8.69119 0.11599C8.51793 0.11599 8.34638 0.150267 8.18645 0.216846C8.02651 0.283425 7.88135 0.38099 7.75934 0.503907Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default RightArrow;
