import React from 'react';
import { any } from 'prop-types';
import styles from './TrustedByCompanies.module.scss';
import { RichText } from 'prismic-reactjs';
import Swiper from 'react-id-swiper';
import Image from '@components/Image/Image';

const TrustedByCompanies = (datas) => {
  // console.log('datas', datas);

  const { primary, items } = datas;

  const params = {
    slidesPerView: 2,
    spaceBetween: 30,

    breakpoints: {
      640: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 5,
      },
      1199: {
        slidesPerView: 7,
      },
    },
  };

  return (
    <div className={styles.trusted_companies_section_wrap}>
      <div className={styles.container}>
        <div className={styles.trusted_companies_section}>
          <div className={styles.trusted_companies_section_img} />

          <div className={styles.title}>
            <RichText render={primary?.title.richText} />
          </div>

          {/* <div className={styles.partners_wrapper}>
            {items &&
              items.map((item, index) => (
                <div className={styles.partner_img_wrap} key={index}>
                  <img
                    src={item?.partners?.url}
                    alt={`img ${index}`}
                    loading="lazy"
                  />
                </div>
              ))}
          </div> */}

          <div className={styles.partners}>
            <Swiper {...params}>
              {items.map((item, index) => {
                return (
                  <div className={styles.slide} key={index}>
                    <Image image={item?.partners} />
                  </div>
                );
              })}
            </Swiper>
          </div>

          <div className={styles.become_partner_wrap}>
            <div className={styles.content_wrapper}>
              <div className={styles.become_partner_title}>
                <RichText render={primary?.contact_text.richText} />
              </div>

              <div className={styles.button_wrap}>
                <a
                  href={
                    primary?.button_link?.url ? primary?.button_link?.url : '#'
                  }
                  target="_blank"
                  rel="noreferrer"
                  className={styles.button}
                >
                  <span>
                    <RichText render={primary?.button_text.richText} />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TrustedByCompanies.propTypes = {
  datas: any,
};

export default TrustedByCompanies;
