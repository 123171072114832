import React from 'react';
// import { RichText } from 'prismic-reactjs';
import styles from './ChooseProgram.module.scss';
import classnames from 'classnames';
import { any } from 'prop-types';
import RightArrow from './svg/RightArrow';
import firstIcon from './image/first-icon.png';
import secdIcon from './image/secd-icon.png';
import thirdIcon from './image/third-icon.png';

// const data = [
//   {
//     name: 'Acquisition',
//     value: 'acquisition',
//     select: true,
//   },
//   {
//     name: 'Sales Cycle',
//     value: 'salesCycle',
//     select: false,
//   },
//   {
//     name: 'Support',
//     value: 'support',
//     select: true,
//   },
// ];

const ChooseProgram = (datas) => {
  // console.log('datas', datas);

  const { primary, items } = datas;

  return (
    <div className={styles.choose_program_wrapper}>
      <div className={styles.container_wrapper}>
        <div className={styles.first_icon_wrap}>
          <img src={firstIcon} alt="firstIcon" loading="lazy" />
        </div>

        <div className={styles.secd_icon_wrap}>
          <img src={secdIcon} alt="secdIcon" loading="lazy" />
        </div>

        <div className={styles.third_icon_wrap}>
          <img src={thirdIcon} alt="thirdIcon" loading="lazy" />
        </div>

        <div className={styles.container}>
          <div className={styles.title_wrapper}>
            <div className={styles.title}>
              <h1>{primary?.title?.richText[0]?.text}</h1>
            </div>

            <div className={styles.subtitle}>
              <h2>{primary?.sub_title?.richText[0]?.text}</h2>
            </div>
          </div>

          <div className={styles.choose_card_wrapper}>
            {items &&
              items?.map((item, index) => (
                <div className={styles.choose_card} key={index}>
                  <div className={styles.choose_card_bg_img} />

                  <div className={styles.choose_card_content_wrapper}>
                    <h4>{item?.card_title?.richText[0]?.text}</h4>
                    <p>{item?.card_description?.richText[0]?.text}</p>

                    <div className={styles.checkbox_wrapper}>
                      <div className={styles.all_checkbox_wrapper}>
                        <div className={styles.single_checkbox_wrapper}>
                          <input
                            type="checkbox"
                            name={item?.acquisition_feature_text?.text}
                            value={item?.acquisition_feature_text?.text}
                            checked={
                              item.is_acquisition_feature &&
                              item.is_acquisition_feature
                            }
                            className={classnames(
                              styles.single_checkbox_input,
                              {
                                [styles.isSelect]: item.is_acquisition_feature,
                              }
                            )}
                            disabled
                          />

                          <span>{item?.acquisition_feature_text?.text}</span>
                        </div>

                        <div className={styles.single_checkbox_wrapper}>
                          <input
                            type="checkbox"
                            name={item?.sales_cycle_feature_text?.text}
                            value={item?.sales_cycle_feature_text?.text}
                            checked={
                              item.is_sales_cycle_feature &&
                              item.is_sales_cycle_feature
                            }
                            className={classnames(
                              styles.single_checkbox_input,
                              {
                                [styles.isSelect]: item.is_sales_cycle_feature,
                              }
                            )}
                            disabled
                          />

                          <span>{item?.sales_cycle_feature_text?.text}</span>
                        </div>

                        <div className={styles.single_checkbox_wrapper}>
                          <input
                            type="checkbox"
                            name={item?.support_feature_text?.text}
                            value={item?.support_feature_text?.text}
                            checked={
                              item.is_support_feature && item.is_support_feature
                            }
                            className={classnames(
                              styles.single_checkbox_input,
                              {
                                [styles.isSelect]: item.is_support_feature,
                              }
                            )}
                            disabled
                          />

                          <span>{item?.support_feature_text?.text}</span>
                        </div>
                      </div>

                      {/* {data?.map((item, index) => (
                        <div
                          className={styles.single_checkbox_wrapper}
                          key={index}
                        >
                          <input
                            type="checkbox"
                            name={item?.name}
                            value={item?.value}
                            checked={item.select && item.select}
                            className={classnames(
                              styles.single_checkbox_input,
                              {
                                [styles.isSelect]: item.select,
                              }
                            )}
                            disabled
                          />

                          <span>{item?.name}</span>
                        </div>
                      ))} */}
                    </div>
                  </div>

                  <div className={styles.card_button_wrapper}>
                    <a
                      href={
                        item?.card_button_link?.url
                          ? item?.card_button_link?.url
                          : '#'
                      }
                      target="_blank"
                      rel="noreferrer"
                      className={styles.button}
                    >
                      <span>{item?.card_button_text?.richText[0]?.text}</span>
                      <div className={styles.icon_wrap}>
                        <RightArrow />
                      </div>
                    </a>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

ChooseProgram.propTypes = {
  datas: any,
};

export default ChooseProgram;
