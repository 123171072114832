import React from 'react';
import styles from './SecurePrivacy.module.scss';
import { any } from 'prop-types';
import { RichText } from 'prismic-reactjs';

const SecurePrivacy = (datas) => {
  // console.log('datas', datas);

  const { primary, items } = datas;

  return (
    <div className={styles.secure_privacy_wrapper}>
      <div className={styles.secure_privacy_wrapper_img} />

      <div className={styles.container}>
        <div className={styles.secure_privacy_all_content}>
          <div className={styles.title}>
            <RichText render={primary?.title.richText} />
          </div>

          <div className={styles.secure_privacy_card_wrapper}>
            {items &&
              items.map((item, index) => (
                <div className={styles.secure_privacy_card} key={index}>
                  <div className={styles.card_content_wraper}>
                    <div className={styles.icon_wraper}>
                      <img src={item.card_image.url} alt="img" loading="lazy" />
                    </div>
                    <div className={styles.desc_wrapper}>
                      <p>{item?.card_description?.richText[0]?.text}</p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

SecurePrivacy.propTypes = {
  datas: any,
};

export default SecurePrivacy;
